import React from 'react'

import Logo from './logo'

const Footer = () => {
  return (
    <footer className="d-flex justify-content-center align-items-center">
      <Logo />
    </footer>
  )
}

export default Footer
