import React from 'react'
import ToggleNav from './togglenav'

import AnchorLink from 'react-anchor-link-smooth-scroll'
import ArrowDownIcon from '../images/Pfeil-01.svg'

const FullSizeImage = (props) => {
  return (
    <div className={props.bgImage}>
      <ToggleNav />
      <div className="scroll-down">
        <AnchorLink offset={() => 100} href="#main-content" rel="nofollow">
          <p>{props.title}</p>
          <img
            src={ArrowDownIcon}
            alt="scroll down icon"
            width="51"
            height="28"
          />
        </AnchorLink>
      </div>
    </div>
  )
}

export default FullSizeImage
