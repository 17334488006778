import React from 'react'

import Logo from './logo'

const Header = () => {
  return (
    <header className="bw-site-header d-flex justify-content-md-center justify-content-end align-items-center">
      <Logo />
    </header>
  )
}

export default Header
