import React from 'react'

const handleToggleClick = () => {
  let el = document.getElementsByClassName('bw-navbar-content')
  let elButton = document.getElementsByClassName('bw-toggle-nav')
  el[0].classList.toggle('bw-nav-visible')
  elButton[0].classList.toggle('bw-toggle-visible')
}

const ToggleNav = () => {
  return (
    <button className="bw-toggle-nav" onClick={handleToggleClick}>
      <i className="bar-label mb-2">MENÜ</i>
      <i className="bar1"></i>
      <i className="bar2"></i>
      <i className="bar3"></i>
    </button>
  )
}

export default ToggleNav
