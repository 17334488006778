import React from 'react'

import Link from 'gatsby-plugin-transition-link'
import TransitionLink from 'gatsby-plugin-transition-link'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const transToggleClick = () => {
  let el = document.getElementsByClassName('bw-navbar-content')
  let elButton = document.getElementsByClassName('bw-toggle-nav')
  el[0].classList.remove('bw-nav-visible')
  elButton[0].classList.remove('bw-toggle-visible')
}

const ListLink = (props) => (
  <li className="bw-nav-item mb-4" onClick={transToggleClick}>
    <AniLink paintDrip to={props.to} duration={1} bg="#141414" hex="#141414">
      {props.children}
    </AniLink>
  </li>
)

const NavContent = () => {
  return (
    <div className="bw-navbar-content">
      <nav className="bw-primary-navigation d-flex align-items-center justify-content-center">
        <ul style={{ listStyle: `none` }}>
          <ListLink to="/">Startseite</ListLink>
          <ListLink to="/bw-spielbanken/">BW-Spielbanken</ListLink>
          <ListLink to="/presse/">Presse</ListLink>
          <ListLink to="/kontakt/">Kontakt</ListLink>
          <ListLink to="/impressum/">Impressum</ListLink>
          <ListLink to="/datenschutz/">Datenschutz</ListLink>
          <ListLink to="/video/">Videoüberwachung</ListLink>
        </ul>
      </nav>
    </div>
  )
}

export default NavContent
