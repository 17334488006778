import React from 'react'

import Header from '../components/header'
import Footer from '../components/footer'
import NavContent from '../components/navcontent'
import FullSizeImage from './fullsizeimage'

import '../styles/global.css'

const Layout = (props) => {
  let locationTitle
  if (props.location.pathname === '/') {
    locationTitle = 'WILLKOMMEN'
  } else {
    locationTitle = 'WEITER'
  }
  return (
    <div className="bw-site-content-wrapper">
      <div className="bw-site-content">
        <Header />
        <FullSizeImage bgImage="bw-full-page-image" title={locationTitle} />
        {props.children}
      </div>
      <Footer />
      <NavContent />
    </div>
  )
}

export default Layout
