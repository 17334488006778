import React from 'react'

import { Link } from 'gatsby'
import LogoImage from '../images/Logo_Header_Spbnk_Dach-01.svg'

const Logo = () => {
  return (
    <Link to="/" className="logo-link">
      <img
        src={LogoImage}
        alt="bw-spielbanken logo image"
        width="403"
        height="45"
        className="logo-img"
      />
    </Link>
  )
}

export default Logo
